/**
  マップ画面のcss
  Material-UIによって自動生成されるクラスで
  src/components/map/MapboxMap.jsに書けないためcssファイルに記述
 */
//GoogleStreetViewへのリンクボタンの調整
#googleStreetView .MuiSvgIcon-root {
  height: 0.8em;
  width: 0.8em;
}
#googleStreetView .MuiButton-label {
  line-height: 0;
}
#gsvDialog button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  font-size: 0.7rem;
  padding: 2px 10px;
}
//距離測定等のコントロール用
.mapboxgl-ctrl-top-right {
  top: 40px !important;
}
.mapbox-gl-draw_line,
.mapbox-gl-draw_polygon {
  position: relative;
}
.mapbox-gl-draw_line.active:after,
.mapbox-gl-draw_polygon.active:after {
  content: "";
  background: #004ea2;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}
.mapbox-gl-draw_line.active:after {
  border-radius: 3px 3px 0 0;
}
button.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line.active:hover {
  background-color: #004ea2;
}
//メモボタンと現在地ボタン用
#memo .MuiSvgIcon-root,
#currentLocation .MuiSvgIcon-root,
#defaultLocation .MuiSvgIcon-root,
#geologyCheck .MuiSvgIcon-root{
  height: 0.8em;
  width: 0.8em;
}
.MuiDialogContent-root {
  padding: 16px 16px 6px !important;
}
.MuiDialogActions-root.MuiDialogActions-spacing {
  padding: 0 16px 16px;
}
#Dialog button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  font-size: 0.8rem;
  padding: 5px 10px 3px 10px;
}
#listingGroup,
#memo,
#googleStreetView,
#currentLocation,
#defaultLocation,
#geologyCheck {
  display: none;
}
#listingGroup.buttonActive,
#memo.buttonActive,
#googleStreetView.buttonActive,
#currentLocation.buttonActive,
#defaultLocation.buttonActive,
#geologyCheck.buttonActive {
  display: block;
}
.mapboxgl-marker {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%230099ff" d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z" /></svg>') no-repeat center center; // 青
  background-size: 100%;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.mapboxgl-marker.mapboxgl-red-marker {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%23ff0000" d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z" /></svg>') no-repeat center center;
  background-size: 100%;
}
//地質図用のマーカー
.geology-marker{
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%230099ff" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" /></svg>') no-repeat center center;
  width: 30px;
  height: 30px;
  background-size: 100%;
}
// 雨量観測地点のマーカー
.weatherMarker{
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%230099ff" d="M12 12c0-3 2.5-5.5 5.5-5.5S23 9 23 12H12zm0 0c0 3-2.5 5.5-5.5 5.5S1 15 1 12h11zm0 0c-3 0-5.5-2.5-5.5-5.5S9 1 12 1v11zm0 0c3 0 5.5 2.5 5.5 5.5S15 23 12 23V12z" /></svg>') no-repeat center center;
  width: 30px;
  height: 30px;
  background-size: 100%;
}
.selectedWeatherMarker{
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="red" d="M12 12c0-3 2.5-5.5 5.5-5.5S23 9 23 12H12zm0 0c0 3-2.5 5.5-5.5 5.5S1 15 1 12h11zm0 0c-3 0-5.5-2.5-5.5-5.5S9 1 12 1v11zm0 0c3 0 5.5 2.5 5.5 5.5S15 23 12 23V12z" /></svg>') no-repeat center center;
}
.mapboxgl-popup {
  max-width: none !important;
  top: 10px;
  width: 270px !important;
  &.weatherPopup{
    width: 90px !important;
  }
}
.mapboxgl-popup-content {
  padding: 10px;
}

// メモ削除ボタン
.deleateMarker {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%2333333" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" /></svg>') no-repeat center center;
  background-size: 100%;
  cursor: pointer;
  height: 23px;
  opacity: 0.6;
  width: 23px;
}
.deleateMarker:hover {
  opacity: 1;
}

// メモ更新ボタン
.UpdateMarker {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%2333333" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" /></svg>') no-repeat center center;
  background-size: 100%;
  cursor: pointer;
  height: 23px;
  opacity: 0.6;
  width: 23px;
  margin-right: 5px;
}
.UpdateMarker:hover {
  opacity: 1;
}
.popArea {
  list-style:  none;
  margin:  0;
  padding: 0;
  text-align: left;
}
.popArea p {
  margin: 0;
}
.popArea ul {
  display: flex;
  list-style: none;
  margin: 0;
  position: relative;
  top: -3px;
}
.popArea .upper {
  display: flex;
  justify-content: space-between;
}
.popArea .userId {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%2333333" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" /></svg>') no-repeat center left;
  background-size: 16px;
  font-size: 14px;
  padding: 0 0px 0px 20px;
  width: 120px;
  word-break: break-all;
}
.popArea .createDateTime {
  bottom: -2px;
  font-size: 11px;
  position: relative;
}
.popArea .memo {
  margin: 13px 0 0;
}
.popArea .title {
  margin: 13px 0 0;
  font-weight: bold;
}
.popArea .bottom {
  display: flex;
  justify-content: space-between;
  margin: 17px 0 0;
}
.popArea .lngLat {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%2333333" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" /></svg>') no-repeat center left;
  background-size: 13px;
  font-size: 11px;
  opacity: 0.6;
  padding: 1px 0 0 14px;
}
.popArea .lngLat p:first-child:after {
  content: ",";
}
.popArea .lng {
  display: inline-block;
  margin: 0 0 0 4px;
}
.popArea .Lat {
  display: inline-block;
}
//スナックバー用
.MuiAlert-message {
  padding: 8px 25px 8px 0 !important;
}
.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter {
  cursor: pointer;
}
.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter:hover {
  opacity: 0.6;
}
//マップ右下のマップボックス表記を非表示
.mapboxgl-ctrl-bottom-right {
  display: none;
}
//検索用
.mapboxgl-ctrl-geocoder {
    width: 450px;
    max-width: none;
}
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input {
  padding: 6px 10px 6px 35px;
}
.accountMenu amplify-sign-out.hydrated {
  text-align: center;
  display: block;
  padding: 5px 0;
  position: relative;
  margin: auto;
  width: 90%;
}
.accountMenu amplify-sign-out.hydrated:after {
  content: "";
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill="%23ffffff" d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" /></svg>') no-repeat center left;
  background-size: 23px;
  position: absolute;
  width: 23px;
  height: 23px;
  left: 15px;
  top: 18px;
}

.MuiAlert-message{
  display: flex;
  align-items: center;
}