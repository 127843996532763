/**
  サマリーオープン時のcss
  Material-UIによって自動生成されるクラスで
  src/components/map/Summary.jsに書けないためcssファイルに記述
 */
 .MuiDrawer-root.MuiDrawer-docked {
  display: flex;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorBottom.MuiDrawer-paperAnchorDockedBottom {
  overflow: visible;
  width: calc(100% - 276px);
}
// 斜面変動ランクのCSS
.rank1 {
  background: #7e017c;
  padding: 2px;
  color: #fff;
  font-weight: bold;
  font-size: 0.8rem;
}
.rank2 {
  background: #fb00ff;
  padding: 2px;
  color: #fff;
  font-weight: bold;
  font-size: 0.8rem;
}
.rank3 {
  background: #fa0800;
  padding: 2px;
  color: #fff;
  font-weight: bold;
  font-size: 0.8rem;
}
.rank4 {
  background: #fdfd12;
  padding: 2px;
  font-weight: bold;
  font-size: 0.8rem;
}
.rank5 {
  background: #0affff;
  padding: 2px;
  font-weight: bold;
  font-size: 0.8rem;
}
.nodataRow{
  th{
    &:first-child{
      .noData{
        padding: 0;
      }
    }
    .noData{
      background: #3b3b3b;
      padding: 2px;
      font-weight: bold;
      font-size: 0.8rem;
      color: #fff;
    }
  }
}
.summaryCommentArea{
  text-align: left;
  .control{
    width: 100%;
    max-width: 500px;
    .inputArea{
      border: 1px solid #dfdfdf;
      padding: 3px 10px 0 10px;
      border-radius: 5px;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
    .commentContent{
      text-align: right;
      button{
        &:last-child{
          margin-left: 10px;
        }
      }
    }
  }
  .resultContent{
    line-height: 1.5;
    border-radius: 4px;
    background-color: #f3f3f3;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    margin-left: 5px;
  }
}
.pointDataArea{
  margin: 5px 0 15px 35px;
  .pointData{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 17px;
    .pointDataItem{
      margin-right: 10px;
      font-size: 0.7rem;
      font-weight: bold;
      span{
        font-size: 0.8rem;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.summaryUnderArea{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  .summaryUnderAreaItem{
    width: 40%;
    &:last-child{
      width: 60%;
    }
  }
}
.aoiPdfLink{
  text-align: left;
  font-size: 0.9rem;
  margin-bottom: 15px;
  margin-top: 10px;
  padding-left: 10px;
  span{
    text-decoration: underline;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }
}