.AoiFilteringModalArea{
  padding: 30px !important;
  .closeBtn{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    padding: 0;
    min-width: initial;
  }
  .titleArea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .tableArea{
    max-height: 400px;
    overflow-x: initial;
    Table{
      tbody{
        tr{
          td{
            padding: 20px;
            &.title{
              background-color: #ececec;
              position: relative;
              width: 20%;
              .tooltipIcon{
                position: absolute;
                top: 10px;
                right: 10px;
                width: 20px;
              }
            }
            &.selectRankArea{
              display: flex;
              justify-content: space-between;
              align-items: center;
              .selectBoxArea{
                width: 48%;
                &.grid{
                  width: 24%;
                }
                .selectBox{
                  font-size: 0.85rem;
                  border-bottom: 1px solid #004ea2;
                }
              }
            }
          }
        }
      }
    }
  }
  .btnArea{
    margin-top: 20px;
    text-align: center;
  }
}

.MuiPaper-elevation24{
  overflow-y: initial !important;
}