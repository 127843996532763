/**
  ドロワーメニューオープン時のcss
  Material-UIによって自動生成されるクラスで
  src/components/map/Drawer.jsに書けないためcssファイルに記述
 */
.aoiArea .MuiFormControlLabel-label {
  text-align: left;
  width: 125px;
}
// カレンダー用
.MuiToolbar-root.MuiToolbar-regular.MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar.MuiToolbar-gutters {
  min-height: 0;
  height: auto;
  padding: 5px 15px;
  justify-content: flex-start;
  flex-direction: row;
}
h4.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-h4.MuiTypography-alignCenter,
h4.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiPickersToolbarText-toolbarBtnSelected.MuiTypography-h4.MuiTypography-alignCenter {
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.00938em;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiPickersToolbarButton-toolbarBtn:not(:first-child) {
  margin: 0 0 0 20px;
}
.MuiFormControl-marginNormal {
  display: flex !important;
  flex-direction: row !important;
  margin: 3px 0 !important
}
//Output Type用
#sideBar li.MuiListItem-root.MuiListItem-gutters {
  padding: 0;
}
#sideBar #startDate-label, #endDate-label {
  font-size: 0.6rem;
  position: relative;
  top: 6px;
}
#sideBar .MuiInputBase-root {
  margin-top: 0;
}
#sideBar .MuiNativeSelect-select.MuiNativeSelect-select {
  padding-left: 5px;
}
.selectDateArea_velocity .MuiInputBase-root {
  border-bottom: 1px solid #fff;
}
//スライダー用
span.MuiSlider-valueLabel > span > span {
  font-size: 0.6rem;
}
span.MuiSlider-valueLabel > span {
  height: 25px;
  width: 25px;
}
span.MuiSlider-valueLabel {
  left: calc(-50% - 0px);
  top: -22px;
}
#sliderAlertYellow .MuiSlider-root {
  color: #FF9900;
}
#sliderAlertYellow .MuiSlider-root.Mui-disabled {
  color: #bdbdbd;
}
#sliderAlertYellow .MuiSlider-root.Mui-disabled .MuiSlider-valueLabel {
  left: 1px;
  position: relative;
  top: -14px;
}
#sideBar .MuiSlider-root {
  height: 4px;
  width: 65%;
}
#sideBar .MuiSlider-rail {
  height: 4px;
}
#sideBar .MuiSlider-track {
  height: 4px;
}
//AOI アラート用
#sliderAlertYellow {
  margin: 25px ​auto 0 !important;
}
#sideBar .aoiArea .MuiInput-formControl {
  margin-top: 16px;
}
#sideBar .aoiArea .MuiNativeSelect-select {
  font-size: 0.9rem;
  padding: 5px;
}
#sideBar .rankSort .MuiNativeSelect-select {
  padding: 10px 0 8px 0;
  font-size: 0.75rem;
  height: auto;
}
#sideBar .aoiArea .MuiInputBase-root {
  display: block;
  margin: 0;
}
#sideBar .aoiArea .none {
  display: none;
}
.MuiInput-underline::before,
.MuiInput-underline:after {
  content: none !important;
}
// ラスタ用
.rasterArea .MuiFormControlLabel-root {
  padding: 0;
  margin-right: 0;
}

//メモタイトル一覧
.memoListArea {
  display: block !important;
  text-align: left;
  padding-top: 0 !important;
  .memoListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    .memoTitleText {
      width: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .memoZoomIcon {
      width: 10%;
    }
  }
}

//解析対象範囲名検索
.analysisNameBox {
  .control{
    display: block;
    .boxArea{
      .inputArea{
        border: 1px solid #dfdfdf;
        padding: 3px 10px 0 10px;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        input {
          font-size: 14px;
        }
      }
    }
  }
}

//初期表示位置の設定
.mapPostion{
  border-top: 1px solid #dfdfdf;
  padding-top: 20px;
}
.NowPostionArea{
  .now{
    text-align: left;
    &:first-child{
      margin-bottom: 5px;
    }
  }
  .btnGroup{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-child{
      margin-bottom: 15px;
    }
    .title{
      font-size: 14.4px;
    }
  }
}

//汎用ラスター
.generalRasterArea{
  padding: 0 16px !important;
  display: block !important;
  &:last-child{
    padding-bottom: 15px !important;
  }
  .generalRasterList{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .subtxt{
    text-align: left;
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin-bottom: 3px;
  }
}

// 解析モード
.modeArea{
  .MuiAccordionSummary-content{
    margin: 0 !important;
  }
}

//変位値のテキストボックス
.inputBox{
  border: 1px solid #dfdfdf;
  padding: 6px 5px 6px 5px;
  border-radius: 5px;
  font-size: 0.9rem !important;
  width: 55px;
  input{
    padding: 0;
  }
}

/**
  共通
*/
.flex_common{
  display: flex;
  justify-content: space-between;
}