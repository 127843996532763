/**
  ジオコーダーのcss
  Material-UIによって自動生成されるクラスで
  src/components/map/MapboxMap.jsに書けないためcssファイルに記述
 */
.mapboxgl-ctrl-geocoder--input {
  height: 30px !important;
}

.mapboxgl-ctrl-geocoder--icon {
  top: 5px !important;
  left: 4px !important;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 29px !important;
  min-width: 29px !important;
}