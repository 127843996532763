/**
  サマリーオープン時のcss
  Material-UIによって自動生成されるクラスで
  src/components/map/Summary.jsに書けないためcssファイルに記述
 */
.MuiDrawer-paperAnchorBottom {
  left: auto !important;
  background: none !important;
  border-top: none !important;
}

.recharts-legend-item {
  font-size: 12px;
  margin: 0 !important;
  font-weight: bold;
}

.tableCell {
  line-height: 1.6 !important;
}

.recharts-legend-wrapper {
  width: auto !important;
  height: auto !important;
  left: 38px !important;
  top: -10px !important;
}

.xAxis text.recharts-text.recharts-cartesian-axis-tick-value {
  stroke: black;
  stroke-width: 0px;
}