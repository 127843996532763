//ダッシュボードの斜面変動ランク一覧をクリック時のポップアップ
.popupAoiListArea{
  th{
      font-size: 0.8rem;
  }
  .MuiTableCell-root{
    border-bottom: 1px solid #2e2e2e;
    &.cellRank1{
      background-color: #7e017c;
      color: #fff;
    }
    &.cellRank2{
      background-color: #fb00ff;
    }
    &.cellRank3{
      background-color: #fa0800;
    }
    &.cellRank4{
      background-color: #fdfd12;
    }
    &.cellRank5{
      background-color: #0affff;
    }
    &.nodata{
      background-color: #3b3b3b;
      color: #fff;
    }
  }
}